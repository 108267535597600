.price-label {
    color: #32324e;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    margin: 15px 0;
}

@property --myColor1 {
	syntax: '<color>';
	initial-value: #e35f21;
	inherits: false;
  }
  
  @property --myColor2 {
	syntax: '<color>';
	initial-value: #32324e;
	inherits: false;
  }

.servicii-section .card-title.card-abonament {
	font-size: clamp(17px, 1.6vw, 24px);
    padding: 5px 20px;
    color: #ffffff;
    background-image: linear-gradient(90deg, var(--myColor1) 7%, var(--myColor2) 7%);
    margin: 0 auto;
    width: 225px;
}

.from-style {
    font-weight: 400;
    margin-bottom: 15px;
}

.abonament-price {
    font-size: 35px;
    font-weight: 700;
}

.abonament-price > small {
    font-size: 20px;
    font-weight: 600;
}