.footer-logo {
    text-align: center;
}

.footer-logo a {
    display: inline-block;
}

.footer-logo img {
	width: 280px;
	height: auto;
}

.footer-social-icon {
    text-align: center;
}

.footer-social-icon a {
    margin-left: 0 !important;
    margin-right: 15px;
}

.footer-social-icon a:last-child {
    margin-right: 0 !important;
}

.footer-middle-text p {
    font-size: clamp(15px, 2.6vw, 20px) !important;
    padding-right: 30px;
    /* line-height: 24px; */
}

.footer-wrap .footer-cta-bg-wrapper {
    padding: 40px 10px;
}

.footer-menu ul li {
    padding: 0 10px;
    line-height: 1;
}

/* .footer-menu ul li:first-child {
    border-right: 2px solid #ffffff;
} */

.footer-menu ul li a {
    margin: 0 !important;
    padding: 0 !important;
    border: none;
    color: #ffffff;
    font-size: clamp(13px, 1.6vw, 16px);
}

.footer-menu ul li a:hover {
    color: #32324d;
}

.footer-cta-wrapper .footer-social-icon a {
    border: 2px solid #32324d;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer-cta-wrapper .footer-social-icon a:hover {
    background-color: #e35e22;
    border: 2px solid #ffffff;
}

.contact-info a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    color: #32324e;
}

.single-contact-info .icon {
    width: 24px;
}

.single-contact-info p {
    line-height: 24px;
    color: #32324e !important;
}

.footer-wrap .single-footer-wid ul li > a {
    line-height: 1;
}

.newsletter_widget form svg {
    position: absolute;
    z-index: 1;
    display: inline-block;
    line-height: 60px;
    left: 20px;
    fill: #FF5E14;
    width: 16px;
    top: 22px;
}

.footer-widgets-wrapper .single-footer-wid a:hover {
    color: #FF5E14 !important;
}

.newsletter_widget form button:hover {
    background: #32324d;
}

.newsletter_widget form button {
    transition: all 0.2s ease-in-out !important;
}

.copyright-info {
    font-size: clamp(13px, 1.6vw, 16px);
}

.partener-logo-slider .slick-slide {
    padding: 0 5px;
}

.partener-logo-slider .slick-slide a {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f8fc;
    border-radius: 14px;
    height: 100px;
    color: #32324d !important;
    padding: 5px 10px;
    overflow: hidden;
}

/* .partener-logo-slider .slick-slide svg {
    width: 100%;
    height: auto;
} */

.partener-logo-slider .slick-slide img {
    width: auto;
    height: auto;
    max-height: 100%;
    pointer-events: none;
}

.footer-bg-images {
    display: flex;
    align-items: flex-end;
}

.footer-bg-images img {
    aspect-ratio: 21 / 9;
    object-fit: cover;
}

/* .partener-logo-slider .slick-track:hover {
    transition: none !important;
} */

.money-style img {
    width: 100%;
    height: 25px;
    object-fit: contain;
}

.footer-wrap .single-footer-wid.site_info_widget .single-contact-info:first-child {
    margin-bottom: 17px !important;
}

@media (max-width: 991.98px) {
    .footer-widgets-wrapper {
        padding: 30px 25px 70px;
    }
    .footer-cta-wrapper .footer-social-icon a {
        margin-right: 10px;
        width: 46px;
        height: 46px;
    }

    .footer-bottom {
        padding: 25px;
    }
}

@media (max-width: 1199.98px) {
    .footer-logo img {
        width: 210px;
    }

    .partener-logo-slider .slick-slide a {
        height: 80px;
    }
}

@media (max-width: 767.98px) {
    .footer-middle-text {
        margin: 20px 0;
        padding: 0 15px;
        text-align: center;
        text-wrap: balance;
    }
    .copyright-info {
        text-wrap: balance;
        margin-bottom: 10px;
    }

    .footer-middle-text p {
        padding-right: 0;
    }

    .footer-bg-images {
		margin-top: 30px;
	}

    .partener-logo-slider .slick-slide a {
        height: 70px;
    }

    .footer-wrap .footer-cta-bg-wrapper {
        padding: 40px 10px 10px;
    }
}

@media (max-width: 450px) {
    .side-box {
        flex-direction: column;
        /* margin-top: 50px; */
    }
}

@media (max-width: 360px) {
    .partener-logo-slider .slick-slide a {
        height: 50px;
        padding: 3px;
    }
}