.mkc_circle { animation: mck_progress 1000ms ease-in-out; }
@keyframes mck_progress { 0% { stroke-dasharray: 0, 100; }}

/* Layout styles only, not needed for functionality */
.grid {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 31em) {
    .grid { grid-template-columns: repeat(4, 1fr);}
}