.utils-info-section {
    padding-bottom: 50px;
}

.utils-info-section .card-img-info {
    width: 90px;
    height: auto;
}

.utils-info-section .card-title {
    margin-bottom: 0;
    padding-left: 20px;
    position: relative;
    top: 10px;
    color: #32324e;
    text-transform: uppercase;
    font-size: clamp(17px, 1.6vw, 20px);
}

.utils-info-section .card-text {
    margin-bottom: 40px;
    color: #32324e;
    line-height: 30px;
    font-size: clamp(13px, 1.6vw, 16px);
}

.utils-info-section .card-body {
    padding: 20px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.utils-info-section .btn-full-width {
    font-size: clamp(13px, 1.6vw, 16px) !important;
}

@media (max-width: 991.98px) {
    .utils-info-section .card-text {
        line-height: 24px;
        margin-bottom: 10px;
    }
}