.custom-header-menu.sticky {
    position: sticky !important;
}

.mobile-menu-burger svg {
    width: 35px;
    height: 35px;
}

.mobile-menu-burger svg path {
    fill: #32324d;
}

.mobile-menu-burger:active,
.mobile-menu-burger:hover {
    background: #32324d !important;
}

.mobile-menu-burger:active svg path,
.mobile-menu-burger:hover svg path {
    fill: #ffffff !important;
}

.custom-mobile-menu {
    display: inline-block;
}

.custom-offcanvas-menu .offcanvas-body nav ul li {
    margin-bottom: 15px;
}

.custom-offcanvas-menu .offcanvas-body nav ul li a {
    color: #32324e !important;
    text-transform: uppercase;
}

.custom-offcanvas-menu .offcanvas-body nav ul li a:hover {
    color: #e35f21 !important;
}

header.custom-header-menu .main-menu ul > li {
    display: inline-flex;
}

.custom-offcanvas-menu.offcanvas {
    max-width: 280px !important;
}

.logo img {
    width: initial;
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    header.header-5 .main-menu ul li a {
        font-size: 0.85rem;
    }

    header.header-1 .main-menu ul > li > a {
        padding: 15px 8px;
    }

    .logo img {
        height: 40px;
    }
}

@media (max-width: 991.98px) {
    .logo img {
        height: 46px;
    }
}

@media (max-width: 767.98px) {
    .custom-header-menu {
        position: sticky !important;
        top: 0;
        z-index: 800;
        right: 0;
        left: 0;
        width: 100%;
        transition: 0.4s;
        box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
        animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
        border: none;
    }
}