@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
	scroll-behavior: smooth;
	/*Adds smooth scrolling instead of snapping to element*/
}

body {
	font-family: 'Poppins', sans-serif !important;
	background: #f7f8fc !important;
}

body p {
	font-family: 'Poppins', sans-serif !important;
}

.font-oswald h1,
.font-oswald h2,
.font-oswald h3,
.font-oswald h4,
.font-oswald h5,
.font-oswald h6,
.font-oswald a {
	font-family: 'Poppins', sans-serif !important;
}

header {
	background: #f7f8fc !important;
}

.logo img {
	width: 100%;
	height: 65px;
}

.d-grid-extra {
	display: grid;
	grid-template: 1fr/ 429fr 160fr;
	padding: 0px;
}

button:disabled,
button[disabled] {
	border: none;
	background-color: #cccccc;
	color: #666666;
	cursor: not-allowed !important;
}

button:hover:disabled,
button[disabled] {
	border: none !important;
	background-color: #cccccc !important;
	color: #666666 !important;
	cursor: not-allowed !important;
}

section {
	/* background-color: #F6F8FC; */
}

.footer-cta-wrapper .footer-cta-bg-wrapper {
	background: #32324e;
}

.footer-cta-wrapper::after {
	background-color: #e35f21;
}

.footer-1 .footer-bottom {
	background-color: #e35f21;
}

.hero-2 .single-slide::before {
	background: #32324e;
}

.hero2 .slide-1 {
	/*background-image: url('assets/top_image.png');*/
}

.hero-2 .single-slide {
	/*background-image: url('assets/top_image.png');*/
	/*height: 450px;*/
	/*background-color: #32324e;*/
}

.hero-2 .hero-contents {
	padding: 44px 0px;
}

.newsletter_widget form button,
.btn-wepper .theme-btns {
	background-color: #e35f21;
}

.slide-title {
	color: #fff;
	font-size: clamp(25px, 4vw, 42px);
	line-height: normal;
	font-weight: 600;
}

.hero-2 .hero-contents p {
	font-size: clamp(14px, 2.3vw, 20px);
}

.slide-title .aa {
	display: block;
	color: #e35f21;
	display: flex;
	align-items: center;
}

.slide-title span {
	position: relative;
	display: flex;
	align-items: center;
	text-align: left;
}

.slide-title span:before {
	content: '';
	width: 20px;
	min-width: 20px;
	height: 20px;
	background: #e35f21;
	position: relative;
	left: 1px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 25px;
}

.slide-title .aa:before {
	background: #ffffff;
}

.side-box {
	/* margin: 0 auto; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* gap: 20px; */
	/* flex-direction: column; */
	/* position: relative;
	right: 0;
	top: 0;
	bottom: 0; */
	-webkit-box-orient: horizontal;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-column-gap: 30px;
	-moz-column-gap: 30px;
	column-gap: 30px;
	/* margin-top: 40px; */
}

.side-box p {
	color: #fff;
	padding-top: 25px;
}

.side-box a {
	width: 350px;
	border-radius: 0px !important;
	text-transform: uppercase;
	/* text-align: center; */
	/* height: 50px; */
	color: #fff;
	padding: 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-2 .hero-contents p {
	color: #fff;
	padding-top: 25px;
}

@property --myColor1 {
	syntax: '<color>';
	initial-value: #e35f21;
	inherits: false;
}

@property --myColor2 {
	syntax: '<color>';
	initial-value: #32324e;
	inherits: false;
}

.side-box a.double-btn {
	/* height: 80px; */
	width: auto !important;
	min-width: 350px;
	background-image: linear-gradient(165deg, var(--myColor1) 50%, var(--myColor2) 50%);
	transition: --myColor1 .3s, --myColor2 .3s;
	border: 2px solid #C2C5D7;
	display: flex;
	align-items: center;
}

.side-box a.double-btn:hover {
	--myColor1: #32324e;
	--myColor2: #e35f21;
	/* transition: background .3s;
	background-image: linear-gradient(165deg, var(--myColor1) 50%, var(--myColor2) 50%) !important; */
}

.side-box a.double-btn span {
	width: 100%;
	display: block;
	/* font-weight: 600; */
	/* font-size: 22px; */
	padding: 0 60px;
	line-height: 1;
	text-wrap: nowrap;
}

.hero-2 .hero-contents .hero-btns {
	margin-top: 75px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: space-around;
	-ms-flex-pack: space-around;
	justify-content: space-around;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-column-gap: 30px;
	-moz-column-gap: 30px;
	column-gap: 30px;
}

.hero-2 .hero-contents .hero-btns a {
	width: 350px;
	border-radius: 0px;
	text-transform: uppercase;
	text-align: center;
	height: 50px;
	padding: 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-2 .hero-contents .hero-btns .btn-1 a:hover {
	background: #32324e;
	border: 2px solid #c2c5d7;
}

/* .hero-2 .hero-contents .hero-btns .btn-1 a:hover {
	background: #32324e;
	border: 2px solid #c2c5d7;
} */

.hero-2 .hero-contents .hero-btns .btn-1 a {
	color: #ffffff;
	background: #e35f21;
	border: 2px solid #e35f21;
}

.factura-pages .hero-2 .hero-contents {
	background-size: cover;
}

/*ABOUT BLOCK*/
.about-section {
	padding: 30px 0;
}

.about-section .block-contents .section-title span {
	color: #32324e;
	text-transform: uppercase;
	font-size: clamp(17px, 1.8vw, 25px);
}

/*ABOUT BLOCK*/

.servicii-section .block-contents .section-title span {
	color: #32324e;
	text-transform: inherit;
	font-size: clamp(17px, 1.8vw, 20px);
}

.servicii-section .icon-check {
	/* display: block; */
	width: 20px;
	height: 20px;
	background: #e36021;
	color: white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: bold;
	position: relative;
	top: 2px;
}

.servicii-section .servicii-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	padding-top: 10px;
}

.servicii-section .servicii-list p {
	padding-left: 20px;
	line-height: 25px;
	font-size: clamp(13px, 1.6vw, 16px);
	color: #32324e;
}

.servicii-section .servicii-list i {
	font-weight: 600;
	font-size: 13px;
}

.servicii-section .card-title {
	font-size: clamp(17px, 1.6vw, 24px);
	color: #32324e;
}

.pay-online-section .pay-online-content p {
	line-height: 30px;
	font-size: clamp(13px, 1.6vw, 16px);
	color: #32324e;
}

.factura-pages .pay-online-section .insert-code-block .text-capitalize {
	font-size: clamp(20px, 2.5vw, 30px);
}

section .card {
	background: #f7f8fc;
	-webkit-box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.1);
	box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.1);
	-webkit-transition: all 0.1s;
	-o-transition: all 0.1s;
	transition: all 0.1s;
}

.utils-info-section .card {
	border: 2px solid #ffffff;
	overflow: hidden;
	height: 100%;
}

.servicii-section .card {
	height: 100%;
	padding: 20px;
	border: 1px solid #eee;
	-webkit-box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.1);
	box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.1);
}

section .card:hover {
	-webkit-box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.3);
	box-shadow: 0px 20px 40px 0px rgba(91, 102, 131, 0.3);
}

.servicii-section .card .card-body {
	padding: 21px 0px 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.servicii-section .card-text {
	margin-bottom: 10px;
	font-size: clamp(13px, 1.6vw, 16px);
	color: #32324e;
}

.servicii-section .card-img {
	background: #edeef3;
	height: 195px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.servicii-section .card-img-top {
	-o-object-fit: cover;
	object-fit: cover;
	height: auto;
	width: 165px;
}

/* PAY ONLINE */

.pay-online-section {
	padding: 0 0 30px;
}

.pay-online-section .about-title h2,
.servicii-section .about-title h2,
.utils-info-section .about-title h2,
.questions-section .about-title h2,
.policy-pages-style .about-title h2,
.about-section .about-title h2 {
	color: #32324e;
	text-transform: uppercase;
	padding-top: 50px;
	padding-bottom: 50px;
	font-size: 40px;
}

.pay-online-section .about-title h2 {
	font-size: clamp(25px, 4vw, 40px) !important;
	/* padding-top: 10px; */
}

.pay-online-section .block-contents .section-title span {
	color: #32324e;
	text-transform: uppercase;
	font-size: clamp(17px, 1.8vw, 25px);
}

.questions-section .btn-read-more,
.pay-online-section .btn-read-more,
.servicii-section .btn-full-width {
	font-size: clamp(13px, 1.6vw, 16px) !important;
}

.btn-full-width {
	height: 50px;
	width: 100%;
	display: block;
	padding: 0 !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	border: 2px solid #e35f21 !important;
	color: #e35f21 !important;
	border-radius: 0px !important;
	font-size: clamp(13px, 1.6vw, 16px) !important;
}

.btn-full-width:hover {
	color: #fff !important;
	background: #e35f21 !important;
}

.btn-full-width:hover i,
.btn-read-more:hover i {
	color: #ffffff !important;
}

.btn-read-more {
	width: 350px;
	height: 50px;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	border: 2px solid #e35f21 !important;
	color: #e35f21 !important;
	border-radius: 0px !important;
}

.btn-full-width i,
.btn-read-more i {
	background: none !important;
	border-radius: 0 !important;
	color: #e35f21 !important;
	right: 20px;
	position: absolute;
	font-weight: bold;
	font-size: 19px !important;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.btn-read-more:hover {
	color: #fff !important;
	background: #e35f21 !important;
}

.btn-send-indicators {
	background: #32324e !important;
	color: #ffffff !important;
	border-color: #32324e !important;
}

.ident-btn {
	height: 50px;
	width: 380px;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #32324e;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	font-size: 1.2rem;
}

.ident-btn:hover {
	color: #fff !important;
	background: #ff5e14 !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.small-reverse-ident-btn {
	height: 2.25rem;
	width: 90%;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #ff5e14;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	/* font-size: 1.2rem; */
	font-size: clamp(13px, 1.6vw, 16px) !important;
}

.small-reverse-ident-btn:hover {
	color: #fff !important;
	background: #32324e !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.small-reverse-ident-btn:hover:disabled,
.small-reverse-ident-btn:hover[disabled] {
	cursor: not-allowed;
}

.small-ident-btn {
	height: 2.25rem;
	width: 75%;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #32324e;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	font-size: clamp(13px, 1.6vw, 16px) !important;
}

.small-ident-btn:hover {
	color: #fff !important;
	background: #ff5e14 !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.xs-btn:hover {
	color: #fff !important;
	background: #ff5e14 !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.xs-btn {
	height: 60px;
	font-weight: bold;
	width: 100%;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #32324e;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	text-transform: none;
	/* padding: 2px 32px; */
	font-size: clamp(10px, 1.3vw, 16px) !important;
}

.xs-reverse-btn:hover {
	color: #fff !important;
	background: #32324e !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.xs-reverse-btn {
	height: 60px;
	font-weight: bold;
	width: 100%;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #ff5e14;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	text-transform: none;
	/* padding: 2px 32px; */
	font-size: clamp(10px, 1.3vw, 16px) !important;
}

.modal-dialog .modal-header {
	border: none;
}

.modal-dialog .modal-body {
	position: relative;
}

.modal-dialog .custom-modal-close {
	position: absolute;
	top: 15px;
	right: 16px;
}

.modal-dialog .custom-modal-title {
	text-align: center;
	padding: 30px 0;
}

.modal-dialog .custom-modal-title i {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-size: 6rem;
	color: #fe5f15;
	display: flex;
	margin-bottom: 40px;
	position: relative;
	height: 6rem;
	justify-content: center;
	align-items: center;
	opacity: .6;
}

.modal-dialog .custom-modal-title i:after {
	content: '';
	width: 8rem;
	height: 8rem;
	border: 5px solid #fe5f15;
	border-radius: 50%;
	position: absolute;
}

.modal-question {
	font-size: 25px;
	color: #32324e;
	font-weight: bold !important;
}

.footer-wrap .single-footer-wid {
	margin-top: 0 !important;
}

.x-btn {
	padding: 0.875rem 1.125rem;
	background: rgb(237, 236, 236);
	font-size: 2.25rem;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.x-btn:hover {
	color: #fff !important;
	background: #32324e !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.text-blue {
	color: #32324e;
}

.code-span {
	position: absolute;
	width: 100%;
	bottom: -2rem;
	right: 0;
	left: 0;
	margin: 0 auto;
	text-align: center;
}

.code-error-input {
	padding: 0.75rem 1rem;
	text-align: center;
	width: 100%;
	color: #ff5e14;
	border: 2px #ff5e14 solid;
}

.back-btn-container {
	top: 2rem;
	max-width: fit-content;
}

.border-l {
	border-left: #32324e 2px solid;
}

.back-btn {
	padding: 0.875rem 1.125rem;
	color: #fff;
	background: #32324e;
	font-size: 2.25rem;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.max-w-300 {
	max-width: 220px;
}

.back-btn:hover {
	color: #fff !important;
	background: #ff5e14 !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.reverse-ident-btn {
	height: 50px;
	width: 380px;
	color: #fff;
	display: block;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	background: #ff5e14;
	border-radius: 0px !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	font-size: 1.2rem;
}

.reverse-ident-btn:hover {
	color: #fff !important;
	background: #32324e !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.x-btn {
	padding: 0.875rem 1.125rem;
	background: rgb(237, 236, 236);
	font-size: 2.25rem;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.x-btn:hover {
	color: #fff !important;
	background: #32324e !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.text-blue {
	color: #32324e;
}

.code-span {
	position: absolute;
	width: 100%;
	bottom: -2rem;
	right: 0;
	left: 0;
	margin: 0 auto;
	text-align: center;
}

.code-input {
	padding: 0.5625rem 1rem;
	text-align: center;
	width: 100%;
	border: 2px #32324e solid;
}

.contor-input {
	padding: 0.15rem 0.25rem;
	width: 75%;
	border: 2px #32324e solid;
}

.processing-text{
	font-size: 16px;
	font-weight: 700;
	color: #e35f21;
}

.animate-input-border {
	animation: borderAnimate 1s ease-in-out;
}

.animate-input-checkbox {
	animation: checkboxAnimate 1s ease-in-out;
}

.animate-input-label {
	animation: textAnimate 1s ease-in-out infinite;
}

.code-error-input {
	padding: 0.75rem 1rem;
	text-align: center;
	width: 100%;
	color: #ff5e14;
	border: 2px #ff5e14 solid;
}

.doc-box {
	padding: 20px;
	/* height: 700px; */
	border: 1px solid gainsboro;
	/* filter: drop-shadow(0px 0px 4px black); */
	-webkit-box-shadow: 1px 5px 30px 10px gainsboro;
	box-shadow: 1px 5px 30px 10px gainsboro;
	margin: 3rem 0;
}

.doc-box img {
	height: 100%;
	width: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}

.td-border-style {
	position: relative;
}

.td-border-style:after {
	content: '';
	position: absolute;
	width: 2px;
	height: 100%;
	background: #32324e;
	right: 35px;
	top: 0;
}

.back-btn {
	padding: 0.875rem 1.525rem;
	color: #fff;
	background: #32324e;
	font-size: 2.25rem;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.btn-container .back-btn {
	padding: 0.875rem 1.825rem !important;
}

.back-btn:hover {
	color: #fff !important;
	background: #ff5e14 !important;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

table.factura-table {
	width: 100%;
	border-collapse: collapse;
	color: #32324e;
	width: 100%;
}

table.factura-table>thead>tr.special-tr {
	border-bottom: #32324e 2px solid !important;
}

table.factura-table>tbody>tr:last-child {
	border-top: #32324e 2px solid !important;
	font-weight: 700;
}

table.factura-table>thead>tr.special-tr>th {
	font-weight: 600 !important;
	/* font-size: 0.875rem; */
	font-size: clamp(12px, 1.3vw, 14px);
}

table.factura-table>tbody>tr>td {
	padding: 0.3rem 0rem !important;
	font-size: clamp(13px, 1.4vw, 16px);
}

table.factura-table>tbody>tr>td:first-child {
	font-weight: 600 !important;
	/* font-size: 0.875rem; */
	font-size: clamp(11px, 2vw, 14px);
}

table.factura-table>tbody>tr {
	border-bottom: 1px solid #32324e47;
}

table.factura-table>tbody>tr:last-child {
	border: none;
}

.big-y-margin {
	margin: 12rem 0;
}

table.factura-table {
	width: 100%;
	border-collapse: collapse;
	color: #32324e;
	width: 100%;
}

.big-y-margin {
	margin: 12rem 0;
}

.btn-pay-now {
	background: #e35f21 !important;
	color: #ffffff !important;
	border-color: #e35f21 !important;
}

.btn-send-indicators:hover {
	border-color: #e35f21 !important;
	background: #e35f21 !important;
}

.btn-pay-now:hover {
	border-color: #32324e !important;
	background: #32324e !important;
}

header.header-5 .main-menu ul li a {
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 25px 10px;
	color: #32324e !important;
}

.btn-wepper .bg-bth,
.btn-wepper .theme-btns {
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	padding: 0 !important;
}

.about-slider-block .about-block-contents {
	padding-left: 30px;
}

.about-slider-block .about-slider-content {
	line-height: 30px;
	font-size: clamp(13px, 1.6vw, 16px);
	color: #32324e;
}

#footer_section,
#questions_section,
#utils_info_section,
#pay_online_section,
#servicii_section,
#about_section {
	scroll-margin-block-start: 100px;
	scroll-margin-top: 100px;
}

.show-special-thead {
	display: none;
}

.hide-special-thead {
	display: table-cell;
}

.modal {
	display: block;
}

.owl-carousel {
	display: inline-block !important;
}

.special-thead-border {
	border-bottom: none !important;
}

.factura-table .special-thead-border td {
	padding-bottom: 0 !important;
}

.show-sm-display {
	display: none;
}

.hide-sm-display {
	display: table-row;
}

.back-btn-block {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.back-btn i {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
}

.back-btn-block .back-btn {
	position: absolute;
	left: 0;
	padding: 13px 20px;
}

.back-btn-block h2 {
	font-size: clamp(22px, 5vw, 30px);
}

/* .footer-widgets-wrapper .footer-bg-images {
	background-position: center !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
} */

.go-to-factura-link {
	color: #e35f21;
	text-decoration: underline;
}

.go-to-factura-link:hover {
	color: #e35f21;
	transition: all 0.2s;
}

.custom-alert-style {
	position: fixed;
    padding: 15px;
    top: 135px;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 690px;
    z-index: 999;
    text-align: center;
    background: #E1EFEC;
    border: 1px solid #13B05C;
    border-radius: 14px;
}

.custom-alert-style .alert-heading svg {
	width: 25px;
    height: 25px;
	color: #ffffff;
	z-index: 999;
}

.custom-alert-style .alert-heading .alert-icon {
	position: relative;
}

.custom-alert-style .alert-heading .alert-icon {
    position: relative;
    height: 50px;
    width: 50px;
	min-width: 50px;
    background: #13B05C;
    display: flex;
    top: 0;
    left: 0;
    z-index: 99;
    border-radius: 50%;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
}

.custom-alert-style .alert-heading {
	font-size: clamp(18px, 4vw, 30px) !important;
    color: #13B05C;
    margin-bottom: 0;
	font-weight: 700;
	display: flex;
    align-items: center;
    justify-content: center;
}

.custom-alert-style .btn-close {
	color: #32324e !important;
	font-size: 15px;
    opacity: 1;
    padding-top: 5px;
}

@keyframes borderAnimate {
	from {
		border-color: #32324e;
	}

	50% {
		border-color: red;
	}

	to {
		border-color: #32324e;
	}
}

@keyframes textAnimate {
	from {
		color: inherit;
	}

	50% {
		color: red;
	}

	to {
		color: inherit;
	}
}

@keyframes checkboxAnimate {
	from {
		padding: 5px;
	}

	50% {
		border-radius: 3px;
		box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 1);
		padding: 7px;
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
	}

	to {	
		padding: 5px;	
	}
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
	.hero-2 .hero-contents {
		padding: 30px;
	}

	.about-slider-block .about-slider-content {
		padding-top: 0.6rem !important;
		line-height: 24px;
		/* font-size: clamp(13px, 1vw, 16px); */
	}

	.servicii-section .servicii-list {
		padding-top: 15px;
	}

	.ident-btn {
		width: 300px;
	}

	.td-border-style:after {
		right: 30px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.about-slider-block .about-slider-content {
		padding-top: 0.6rem !important;
		line-height: 24px;
		/* font-size: clamp(13px, 1vw, 16px); */
	}

	.about-slider-block .about-block-contents {
		padding-left: 15px;
	}

	.btn-full-width i {
		margin-right: 5px !important;
		font-size: 17px !important;
		right: 5px;
	}

	.ident-btn {
		width: 300px;
	}

	.td-border-style:after {
		right: 25px;
	}

	.side-box a.double-btn span {
		padding: 0 20px;
	}
}

@media (max-width: 991.98px) {
	.hero-2 .hero-contents p {
		text-wrap: balance;
	}

	.servicii-section .servicii-list p {
		padding-left: 15px;
		line-height: 24px;
	}

	.pay-online-section .pay-online-content p {
		line-height: 24px;
	}

	/* .servicii-section .icon-check {
		width: 30px;
		height: 30px;
	} */

	.servicii-section .servicii-list {
		padding-top: 15px;
	}

	.servicii-section .card-text {
		line-height: 24px;
		margin-bottom: 10px;
	}

	.servicii-section {
		padding: 0 0 30px
	}

	table.factura-table>thead>tr>th,
	table.factura-table>tbody>tr>td {
		line-height: 18px;
	}

	.side-box a.double-btn span {
		padding: 0 20px;
	}

	.payment-user-info {
		flex-direction: column;
	}

	.back-to-factura-btn {
		font-size: 22px !important;
		padding: 0 20px !important;
		position: absolute;
		left: 0;
		top: 0;
	}

	.payment-title-block .w-100 {
		padding-left: 55px;
	}

	.payment-img {
		padding: 55px !important;
	}

	.pay-online-section .about-title h2,
	.servicii-section .about-title h2,
	.utils-info-section .about-title h2,
	.questions-section .about-title h2,
	.policy-pages-style .about-title h2,
	.about-section .about-title h2 {
		padding-top: 10px;
		padding-bottom: 25px;
	}

	.utils-info-section {
		padding-bottom: 30px !important;
	}

	/* .small-reverse-ident-btn,
  .small-ident-btn {
    font-size: inherit !important;
    font-size: clamp(13px, 1.6vw, 16px) !important;
  } */
}

@media (max-width: 767.98px) {
	.hero-2 .hero-contents .hero-btns a {
		width: 200px;
	}

	.side-box a {
		width: 200px;
	}

	.hero-2 .hero-contents .hero-btns {
		margin-top: 50px;
	}

	.about-slider-block .about-block-contents {
		padding: 50px 30px 0;
	}

	.factura-pages .pay-online-section .insert-code-block {
		flex-direction: column !important;
	}

	.factura-pages .pay-online-section .insert-code-block .text-capitalize {
		margin-bottom: 20px !important;
	}

	.factura-pages .pay-online-section .insert-code-block .data-input-el {
		margin-bottom: 40px;
		width: 75% !important;
	}

	.factura-pages .pay-online-section .insert-code-block .ident-btn {
		width: 100% !important;
	}

	.side-box a.double-btn {
		min-width: 315px;
	}

	.side-box a.double-btn span {
		padding: 0 10px;
	}

	.custom-alert-style .alert-heading .alert-icon {
		height: 40px;
		width: 40px;
		min-width: 40px;
	}

	.custom-alert-style {
		top: 110px;
	}
}

@media (min-width: 630px) and (max-width: 767.98px) {
	.td-border-style:after {
		right: 20px;
	}
}

@media (max-width: 629.98px) {
	.td-border-style:after {
		right: 10px;
	}
}

@media (max-width: 460px) {
	.hero-2 .hero-contents .hero-btns {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.hero-2 .hero-contents .hero-btns .btn-1 {
		margin-bottom: 20px;
	}
}

@media (max-width: 500px) {

	table.factura-table>thead>tr>th.td-border-style span,
	table.factura-table>tbody>tr>td.td-border-style span {
		padding-right: 10px !important;
		display: inline-block;
	}

	.small-ident-btn {
		width: 100% !important;
	}
}

@media (max-width: 420px) {

	.questions-section .btn-read-more,
	.pay-online-content .btn-read-more {
		width: 100%;
	}

	.servicii-section .card-title.card-abonament {
		width: 100% !important;
	}
	.payment-img {
		padding: 35px !important;
	}

	.custom-alert-style .alert-heading .alert-text {
		padding-right: 40px;
	}

	.custom-alert-style {
		padding: 10px;
	}

	.custom-alert-style .alert-heading .alert-icon {
		height: 30px !important;
		width: 30px !important;
		min-width: 30px !important;
		margin-right: 10px !important;
	}
}

@media (max-width: 576px) {
	.show-special-thead {
		display: table-row;
	}

	.hide-special-thead {
		display: none !important;
	}

	.small-reverse-ident-btn {
		width: 100%;
	}

	.show-sm-display {
		display: table-row;
	}

	.hide-sm-display {
		display: none;
	}

	.questions-section .btn-read-more,
	.pay-online-section .btn-read-more,
	.servicii-section .btn-full-width {
		width: 100%;
	}

	.hero-2 .hero-contents .hero-btns {
		margin-top: 40px;
		flex-direction: column;
	}

	.side-box a.double-btn {
		margin-bottom: 20px;
	}

	.slide-title span:before {
		margin-right: 20px;
	}

	.back-to-factura-btn {
		font-size: 18px !important;
		padding: 0 15px !important;
	}

	.custom-alert-style .alert-heading .alert-icon {
		height: 35px;
		width: 35px;
		min-width: 35px;
		margin-right: 15px;
	}

	.custom-alert-style .alert-heading svg {
		width: 20px;
		height: 20px;
	}

	.footer-menu ul {
		flex-direction: column;
	}

	.footer-menu ul li:first-child {
		margin-bottom: 15px;
	}
}

@media (max-width: 320px) {
	.side-box a.double-btn {
		min-width: 270px;
	}

	.btn-full-width i,
	.btn-read-more i {
		right: 7px;
	}

	.back-to-factura-btn {
		font-size: 18px !important;
    	padding: 5px 10px !important;
		line-height: 1;
	}
}