.questions-section .section-title span {
    margin-bottom: 0;
    color: #32324e;
    text-transform: none;
    font-size: clamp(17px, 1.8vw, 25px);
}

.questions-section .card-text {
    color: #32324e;
    line-height: 30px;
    font-size: clamp(13px, 1.6vw, 16px);
}

.questions-section .accordion-body {
    font-size: clamp(13px, 1.6vw, 16px);
    line-height: 24px;
}

.questions-section .accordion .accordion-button {
    color: #32324e;
    font-size: clamp(14px, 1.8vw, 18px);
    line-height: 24px;
    font-weight: bold;
    border-radius: 0;
    background-color: #edeef3;
    /* padding: 12px 12px 12px 20px; */
    padding: 12px 12px 12px 20px;
    text-wrap: pretty;
}

.questions-section .accordion-item {
    margin-bottom: 18px;
    border: none;
}

.questions-section .accordion-item:first-of-type,
.questions-section .accordion-item:last-of-type,
.questions-section .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 0;
}

.questions-section .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questions-section .custom-accordion-button-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 50%;
    background-color: #e35f21;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.questions-section .accordion-button .custom-accordion-button-icon::after {
    background-image: none;
    color: #ffffff;
    font-weight: 500;
    position: absolute;
    line-height: 1;
}

.questions-section .accordion-button.collapsed .custom-accordion-button-icon::after {
    content: '+';
}

.questions-section .accordion-button .custom-accordion-button-icon::after {
    content: '-';
}

.questions-section .accordion-button::after {
    display: none;
}

.utils-info-list li {
    padding-left: 15px;
    position: relative;
}

.utils-info-list li:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: #E35F1F;
    display: inline-block;
    left: 0px;
    top: 12px;
}

@media (max-width: 991.98px) {
    .questions-section {
        padding: 0 25px;
    }

    .questions-section .card-text {
        line-height: 24px;
        margin-bottom: 10px;
    }
}