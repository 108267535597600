.back-to-factura-btn {
    background: #32324e;
    color: #ffffff;
    font-size: 27px;
    padding: 8px 30px;
    font-weight: 600;
    /* line-height: 1; */
}

.back-to-factura-btn > i.fa-solid {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
}

.back-to-factura-btn:hover {
    background: #e35f21;
    color: #ffffff;
}

.payment-img {
    border: 2px solid #D5DFF2;
    border-radius: 14px;
    padding: 75px;
}

.payment-img .lf-player-container {
    transform: scale(1.2);
}

.payment-block {
    padding-top: 50px;
}

.payment-block h2 {
    color: #e35f21;
    text-transform: none;
    font-size: clamp(25px, 5vw, 40px);
    line-height: 1;
    padding: 0 15px;
}

.payment-block .cancel-text {
    color: #32324e;
    font-size: clamp(17px, 3vw, 19px);
    font-weight: 500;
    margin-bottom: 10px;
}

.payment-block h2.success-text-style {
    color: #50ae65;
}

.payment-user-info p {
    font-size: clamp(17px, 3vw, 19px);
    color: #32324e;
    font-weight: 500;
}

.payment-title-block {
    position: relative;
}