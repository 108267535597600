.modalContent p {
    text-align: center;
    margin: 10px 0px;
    font-size: 20px;
}

.modal-body ol {
    padding-left: 50px;
}

.modal-body li:first-letter {
    text-transform: uppercase
}

.modal-body .alternating-colors li {
    color: #4F4F4F;
    padding-left: 16px;
    margin-top: 20px;
    position: relative;
    font-size: 16px;
    line-height: 20px;
}

.modal-body .alternating-colors li:before {
    /* border: 2px solid #ddd; */
    /* border-radius: 50%; */
    content: "";
    display: block;
    height: 2px;
    left: -26px;
    position: absolute;
    top: 7px;
    width: 20px;
}

.alternating-colors {
    padding-top: 0px;
    padding-bottom: 30px;
}

.alternating-colors.border-bottom {
    border-bottom: 1px solid #32324e;
}

.modal-body ol.alternating-colors li:nth-child(odd):before {
    background: #e35f21;
}

.modal-body ol.alternating-colors li:nth-child(even):before {
    background: #32324e;
}

@media (max-width: 576px) {
    .modal-body li {
        font-size: 14px;
    }

    .modal-body ol {
        padding-left: 30px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif
}

a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    margin: 0;
    transition: all .4s;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s
}

a:focus {
    outline: none !important
}

a:hover {
    text-decoration: none;
    color: #57b846
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    margin: 0
}

ul,
li {
    margin: 0;
    list-style-type: none
}

input {
    outline: none;
    border: none
}

textarea {
    outline: none;
    border: none
}

textarea:focus,
input:focus {
    border-color: transparent !important
}

input:focus::-webkit-input-placeholder {
    color: transparent
}

input:focus:-moz-placeholder {
    color: transparent
}

input:focus::-moz-placeholder {
    color: transparent
}

input:focus:-ms-input-placeholder {
    color: transparent
}

textarea:focus::-webkit-input-placeholder {
    color: transparent
}

textarea:focus:-moz-placeholder {
    color: transparent
}

textarea:focus::-moz-placeholder {
    color: transparent
}

textarea:focus:-ms-input-placeholder {
    color: transparent
}

input::-webkit-input-placeholder {
    color: #999
}

input:-moz-placeholder {
    color: #999
}

input::-moz-placeholder {
    color: #999
}

input:-ms-input-placeholder {
    color: #999
}

textarea::-webkit-input-placeholder {
    color: #999
}

textarea:-moz-placeholder {
    color: #999
}

textarea::-moz-placeholder {
    color: #999
}

textarea:-ms-input-placeholder {
    color: #999
}

button {
    outline: none !important;
    border: none;
    background: 0 0
}

button:hover {
    cursor: pointer
}

iframe {
    border: none !important
}

.bg-contact2 {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.wrap-contact2 {
    width: 790px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 72px 55px 90px
}

.contact2-form {
    width: 100%
}

.contact2-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 39px;
    color: #333;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 30px
}

.wrap-input2 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px
}

.input2 {
    display: block;
    width: 100%;
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555;
    line-height: 1.2
}

.focus-input2 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none
}

.focus-input2::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: #e35f21;
    background: -webkit-linear-gradient(45deg, #e35f21, #e53935);
    background: -o-linear-gradient(45deg, #e35f21, #e53935);
    background: -moz-linear-gradient(45deg, #e35f21, #e53935);
    background: linear-gradient(45deg, #e35f21, #e53935)
}

.focus-input2::after {
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: Poppins-Regular;
    font-size: 13px;
    color: #999;
    line-height: 1.2;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

input.input2 {
    height: 45px
}

input.input2+.focus-input2::after {
    top: 16px;
    left: 0
}

textarea.input2 {
    min-height: 115px;
    padding-top: 13px;
    padding-bottom: 13px
}

textarea.input2+.focus-input2::after {
    top: 16px;
    left: 0
}

.input2:focus+.focus-input2::after {
    top: -13px
}

.input2:focus+.focus-input2::before {
    width: 100%
}

.has-val.input2+.focus-input2::after {
    top: -13px
}

.has-val.input2+.focus-input2::before {
    width: 100%
}

.container-contact2-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px
}

.wrap-contact2-form-btn {
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 2px;
    width: auto;
    overflow: hidden;
    margin: 0 auto
}

.contact2-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #e35f21;
    background: -webkit-linear-gradient(-135deg, #e35f21, #e53935, #e35f21, #e53935);
    background: -o-linear-gradient(-135deg, #e35f21, #e53935, #e35f21, #e53935);
    background: -moz-linear-gradient(-135deg, #e35f21, #e53935, #e35f21, #e53935);
    background: linear-gradient(-135deg, #e35f21, #e53935, #e35f21, #e53935);
    top: 0;
    left: -100%;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.contact2-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 244px;
    height: 50px;
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #fff;
    line-height: 1.2
}

.wrap-contact2-form-btn:hover .contact2-form-bgbtn {
    left: 0
}

.complex-list li {
    display: flex;
}

.complex-list-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-weight: 900;
    margin-right: 10px;
    position: relative;
    top: 2px;
    transform: scale(1.3);
    /* stroke-width: .5; */
}

.complex-title>span {
    opacity: .7;
}

@media(max-width:576px) {
    .wrap-contact2 {
        padding: 72px 15px 90px
    }
}

.validate-input {
    position: relative
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s
}

.alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}

@media(max-width:992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1
    }
}